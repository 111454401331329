
/* Typography
--------------------------------------------- */
body,
button,
input,
select,
optgroup,
textarea {
	font-weight: 500;
	font-size: 14px;
	font-family: $font_text;
	line-height: 20px;
	color: $color_text_para;
	@media (max-width: 769px) {
		font-size: 1.4rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	line-height: 1.42857143;
}

h1{
	font-size:3.2rem;
}

h2{
	font-size:4rem;
}

h3{
	font-size:1.5rem;
}

h4{
	font-size:1.6rem;
}
h5{
	font-size:1.4rem;
}

h6{
	font-size: 1rem;
}
h3{
	padding-top: 15px;
	margin-bottom: 0;
	font-size: 18px;
	font-weight: 700;
}
.table th, .table td {
	font-size: 14px;
	font-family: $font_text;
	color: $color_text_para;
}
p {
	font-weight: 500;
	font-size: 14px;
	font-family: $font_text;
	line-height: 20px;
	color: $color_text_para;
	@media (max-width: 769px) {
		font-size: 1.4rem;
	}
}

dfn,
cite,
em,
i {
	font-style: italic;
}

blockquote {
	margin: 0 1.5em;
}

address {
	margin: 0 0 1.5em;
}

pre {
	background: #eee;
	font-family: "Courier 10 Pitch", courier, monospace;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code,
kbd,
tt,
var {
	font-family: monaco, consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}

abbr,
acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}

mark,
ins {
	background: #fff9c0;
	text-decoration: none;
}

big {
	font-size: 125%;
}