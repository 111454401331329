/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget {
	margin: 0 0 1.5em;
	/* Make sure select elements fit in widgets. */
}

.widget-area ul {
	margin: 0;
}

.widget-area a {
	color: #948e8e;
}

.widget-area ul li {
	border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
	margin-bottom: 5px;
	padding-bottom: 5px;
	line-height: 32px;
}

.widget-area .widget .widget-title {
	width: 100%;
	height: 45px;
	line-height: 45px;
	text-align: left;
	padding-left: 20px;
	background-color: #fafafa;
	border-left: 4px solid $secondary_color;
	margin:0;
	text-transform: uppercase;
	display: inline-block;
	color: $secondary_color;
	font-size: 16px;
	font-weight: 600;
	overflow: hidden;
}

.widget_archive ul,
.widget_categories ul,
.widget_recent_entries ul,
.widget_meta ul,
.widget_recent_comments ul,
.widget_rss ul,
.widget_pages ul,
.widget_nav_menu ul {
	list-style: outside none none;
	margin: 0;
	padding: 0;
}

.widget_archive li,
.widget_categories li,
.widget_recent_entries li,
.widget_meta li,
.widget_recent_comments li,
.widget_rss li,
.widget_pages li,
.widget_nav_menu li {
	border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
	margin-bottom: 5px;
	padding-bottom: 5px;
}

.widget_archive a,
.widget_categories a,
.widget_recent_entries a,
.widget_meta a,
.widget_recent_comments li,
.widget_rss li,
.widget_pages li,
.widget_nav_menu li {
	color: #333333;
	font-size: 16px;
	line-height: 32px;
	padding-left: 22px;
	position: relative;
	-webkit-transition: color 0.3s ease;
	-moz-transition: color 0.3s ease;
	-ms-transition: color 0.3s ease;
	-o-transition: color 0.3s ease;
	transition: color 0.3s ease;
}

.widget a:hover,
.widget a:hover::before,
.widget li:hover::before {
	color: rgba(205, 195, 9, 1);
}

.widget_archive a::before,
.widget_categories a::before,
.widget_recent_entries a::before,
.widget_meta a::before,
.widget_recent_comments li::before,
.widget_rss li:before,
.widget_pages li:before,
.widget_nav_menu li:before {
	color: #333333;
	content: "\f187";
	font-family: "Font Awesome 5 Free";
	font-size: 14px;
	font-weight: 700;
	left: 0;
	position: absolute;
	top: 0px;
	-webkit-transition: color 0.3s ease;
	-moz-transition: color 0.3s ease;
	-ms-transition: color 0.3s ease;
	-o-transition: color 0.3s ease;
	transition: color 0.3s ease;
}

.widget_categories a::before {
	content: "\f07b";
	top: 0;
}

.widget_archive a::before {
	top: 0;
}

.widget_recent_entries a::before {
	content: "\f101";
	top: -5px;
}

.widget_meta a::before,
.widget_nav_menu li:before {
	content: "\f138";
	top: -5px;
}

.widget_recent_comments li::before {
	content: "\f086";
	top: 0;
}

.widget_rss li::before {
	content: "\f09e";
	top: 0;
}

.widget_pages li:before {
	content: "\f1ea";
	top: 0;
}

.widget_recent_entries .post-date {
	color: #8f8f8f;
	display: block;
	font-size: 11px;
	margin-top: -5px;
	padding-left: 24px;
}

.widget_nav_menu li:before {
	top: 0;
}
