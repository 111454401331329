/* Links
--------------------------------------------- */
a {
  color: #000;
}

a:visited {
  color: #000;
}

a:hover,
a:focus,
a:active {
  color: #000;
  text-decoration: none;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

/* Forms
--------------------------------------------- */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
  transition: all ease 0.4s;
  padding: 13px 42px;
  color: $color_white;
  background-color: $primary_color;
  border: 1px solid $primary_color;
  cursor: pointer;
  font-weight: 500;
  border-radius: 0px;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  border-color: $secondary_color;
  background: $secondary_color;
}

button:active,
button:focus,
input[type="button"]:active,
input[type="button"]:focus,
input[type="reset"]:active,
input[type="reset"]:focus,
input[type="submit"]:active,
input[type="submit"]:focus {
  border-color: $secondary_color;
  background: $secondary_color;
}

/*--------------------------------------------------------------
## buttons
--------------------------------------------------------------*/
.btn_link {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  padding: 5px 28px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 0px;
  position: relative;
  z-index: 9;
  overflow: hidden;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  background: transparent;
  color: #222222;
  border: 1px solid $primary_color;

  &:hover {
    background: $secondary_color;
    color: #FFF !important;
    border: 1px solid $secondary_color;
    letter-spacing:1px;
    transition: all 0.3s ease;
  }
  &:visited {
    color: #222222;
  }
}

.btn_link--transparent {
  background: transparent;
  color: $color_white;
  border: 1px solid $secondary_color;
  transition: all 0.3s ease;

  &:hover {
    background: $secondary_color;
    color: $color_white;
    border: 1 ps solid $secondary_color;
  }
}

.bg-primary {
  background-color: #337ab7 !important;
  color: $color_white;
}