/*************************************************************************************

Breadcrumbs section

**************************************************************************************/

.breadcrumb {
  position: relative;
  overflow: hidden;
  padding: 0px;
  background: url(../images/slider-1.jpg) center;
  background-size: cover;
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
}

.breadcrumb.transparent_header_breadcrumb {
  padding: 80px 0px 13px 0px;

}

.breadcrumb_wrapper {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 736px) {
    flex-wrap: wrap;
  }
}

.breadcrumb h2 {
  color: $color_white;
  font-size: 40px;
  font-weight: 500;
  margin: 0;
  line-height: 110%;
  text-transform: capitalize;
  display: table-cell;
  vertical-align: middle;
  font-family: $font_title;
}

.breadcrumb nav {
  display: table-cell;
  vertical-align: middle;
}

.breadcrumb ul {
  text-align: right;
  color: $color_white;
  padding: 0;
  margin: 0;
}

.breadcrumb ul li {
  display: inline;
  font-size: 16px;
  color: $color_white;
  font-weight: 500;
}

.breadcrumb ul li a {
  color: $color_white;
  transition: 0.8s ease;
}

.breadcrumb ul li a:after {
  content: ">";
  color: $color_white;
  padding: 0px 8px;
  position: relative;
  top: 1px;
}

.breadcrumb ul li:last-child a:after {
  content: "";

}
